<template>
  <!-- 对战规则 -->
  <div class="big">
    <!-- title -->
    <div class="title" style="text-align: center;margin-top:1rem;">
      <p class="titletext family">对战规则</p>
    </div>
    <!-- 内容 -->
    <div class="nav">
      <h4 class="navtitle">关于对战有些话：</h4>
      <p>
        1.
        创建竞技场时，可以选择乱斗人数（2人对战，3人对战和4人对战），一场乱斗最多可以选择
        6 个盲盒。房间一但创建，不可取消。
      </p>
      <p>
        2.
        竞技场入场费用即本场竞技开启的盲盒价格总和，支付费用后，即视为加入房间，中途退出房间，并不会退还费用。
      </p>
      <p>
        3.
        当一场竞技有多名赢家均分奖励时，非本人所得饰品将被兑换为硬币发放到赢家的账户余额。
      </p>
      <p>
        4.
        每场游戏的结果受到（创建时间，玩家ID，玩家位置和开始时间等）多方面因素影响，为完全随机产生的结果。任意盲盒奖励均可以验证公平性。
      </p>
      <p>
        声明：52skins官方将根据实际运营情况保留随时调整返佣比例的权利，以上活动最终解释权归52skins所有!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.go('mysteryBox');
    },
  },
};
</script>

<style scoped>
.title {
  padding-left: 20px;
  align-items: center;
  justify-content: space-between;
}
.titletext {
  margin-left: -10px;
  font-size: 30px;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 2px #98d462;
}
.nav {
  background-color: #03509a;
  margin:20px 15px;
  font-size: 16px;
  padding: 5%;
}
.navtitle{
    color: #c3b53c;
    margin-bottom: 10px;
    font-weight: bolder;
    font-size: 20px;
}
.nav p:last-child{
    margin-top: 10px;
}
</style>